import styles from './css/Customers.module.css';
import SectionHeader from '../../../common/header/SectionHeader';

const Customers = () => {
    return (
        <section className={styles['customers']}>
            <SectionHeader text="Trusted by these companies" />
            <ul className={styles['companies']}>
                <li>
                    <img src='/images/Digitalfingerprint logo.png' alt='Digitalfingerprint' />
                </li>
                <li>
                    <img src='/images/Afrinov logo.png' alt='Afrinov' />
                </li>
            </ul>
        </section>
    )
};

export default Customers;