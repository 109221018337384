import styles from './css/Testimonials.module.css';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SectionHeader from '../../../common/header/SectionHeader';

const userTestimonials = [
  {
    avatar: <Avatar alt="Remy Sharp" src="https://media.licdn.com/dms/image/C4D03AQFgvEzMpY-2vw/profile-displayphoto-shrink_400_400/0/1625243897858?e=1722470400&v=beta&t=wblFWtjYTxFvUBw90YzaVKbrAquU4_a3t6M8prpphlQ" />,
    name: 'Mashudu Mbedzi',
    occupation: 'CEO at Digital Fingerprint (Pty) Ltd',
    testimonial:
      "I absolutely love how versatile this product is! Whether I'm tackling work projects or indulging in my favorite hobbies, it seamlessly adapts to my changing needs. Its intuitive design has truly enhanced my daily routine, making tasks more efficient and enjoyable.",
  },
  {
    avatar: <Avatar alt="Simbongile Bantuban" src="https://media.licdn.com/dms/image/C4D03AQEkHcR4WUc8NA/profile-displayphoto-shrink_400_400/0/1578059992650?e=1722470400&v=beta&t=fqyiCU1eeAxlPbga6xcrhUBcXGxtTVGTV18QDD-RXkI" />,
    name: 'Simbongile Bantubani',
    occupation: 'Managing Director at Afrinov (Pty) Ltd',
    testimonial:
      "One of the standout features of this product is the exceptional customer support. In my experience, the team behind this product has been quick to respond and incredibly helpful. It's reassuring to know that they stand firmly behind their product.",
  },
];

const logos = [
  'https://media.licdn.com/dms/image/C4D0BAQFdw2W-8TsyBA/company-logo_200_200/0/1658284210388?e=1725494400&v=beta&t=MDBZ79_gEUcZrFW-nzuElxM9xBKonr2MD4UR9VCpDQM',
  'https://media.licdn.com/dms/image/C4D0BAQExCH-lBbmhJg/company-logo_200_200/0/1660598401660?e=1725494400&v=beta&t=9xFvAeyUb8t9wtBpvwrSmZ5D-LvHXdcmzWDEkv3sHSg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

const Testimonials = () => {
  return (
    <section id="testimonials" className={styles['testimonials']}>
      <SectionHeader text="Testimonials" info="Hear from our satisfied customers who have transformed their business with Salesdam." />
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Grid container spacing={2}  sx={{ display: 'flex', justifyContent: 'center',}}>
          {userTestimonials.map((testimonial, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{ display: 'flex'}}
            >
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                  p: 1,
                }}
              >
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {testimonial.testimonial}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pr: 2,
                  }}
                >
                  <CardHeader
                    avatar={testimonial.avatar}
                    title={testimonial.name}
                    subheader={testimonial.occupation}
                  />
                  <img
                    src={logos[index]}
                    alt={`Logo ${index + 1}`}
                    style={logoStyle}
                  />
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default Testimonials;
