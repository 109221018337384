import {  BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import LandingPage from './components/pages/landingPage/LandingPage';
import SignUp from './components/pages/signUp/SignUp';

const PageRouter = () => {
	const paths = [
        {component: <LandingPage />, link: '/'},
        {component: <SignUp />, link: '/signup'},
        {component:  <p>Nothing to show! 404</p>, link: '*'}
    ];

    return (
        <Router>
            <Routes>
				{paths.map((pathItem, index) => (
                    <Route key={index} path={pathItem.link} element={pathItem.component} />
                ))}
            </Routes>
        </Router>
    );
};

export default PageRouter;