// Footer.jsx
import styles from './Footer.module.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 4,
          textAlign: 'center',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Salesdam</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Your data powerhouse for streamlined business success!
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 4,
            mb: 2,
          }}
        >
          <HashLink smooth to="/#">
            About
          </HashLink>
          <HashLink smooth to="/#features">
            Services
          </HashLink>
          <Link to="/contact">
            Contact
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
            mb: 2,
          }}
        >
          <IconButton to="https://www.facebook.com" target="_blank" color="inherit">
            <FacebookIcon />
          </IconButton>
          <IconButton to="https://www.twitter.com" target="_blank" color="inherit">
            <TwitterIcon />
          </IconButton>
          <IconButton to="https://www.linkedin.com" target="_blank" color="inherit">
            <LinkedInIcon />
          </IconButton>
        </Box>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          © {new Date().getFullYear()} Salesdam. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
