import styles from './css/Features.module.css';
import SectionHeader from '../../../common/header/SectionHeader';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';

const items = [
  {
    icon: <TrendingUpRoundedIcon />,
    title: 'Optimized Performance',
    description:
      'Boost your productivity and efficiency with our advanced performance optimization techniques.',
  },
  {
    icon: <CodeRoundedIcon />,
    title: 'Robust Architecture',
    description:
      'Built on a sturdy foundation, our platform ensures stability and reliability for your operations.',
  },
  {
    icon: <FavoriteRoundedIcon />,
    title: 'Seamless Integration',
    description:
      'Effortlessly integrate our solutions into your workflow for a smooth and cohesive experience.',
  },
  {
    icon: <SyncRoundedIcon />,
    title: 'Agile Functionality',
    description:
      'Stay ahead of the curve with our constantly evolving features, tailored to meet your dynamic needs.',
  },
  {
    icon: <SecurityRoundedIcon />,
    title: 'Trusted Security',
    description:
      'Rest easy knowing that your data is protected by industry-leading security measures.',
  },
  {
    icon: <StorageRoundedIcon />,
    title: 'Scalable Solutions',
    description:
      'Grow your business without limitations, thanks to our scalable and flexible infrastructure.',
  },
];

const Features = () => {
  return (
    <section id="features" className={styles['features']}>
      <SectionHeader
        text="Product Features"
        info="Discover the cutting-edge features of Salesdam that streamline your operations and drive efficiency."
      />
      <Box
        sx={{
          color: 'var(--white-color)',
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Grid container spacing={2.5}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Stack
                  direction="column"
                  color="inherit"
                  component={Card}
                  spacing={1}
                  useFlexGap
                  sx={{
                    p: 3,
                    height: '100%',
                    border: '1px solid',
                    background: 'transparent',
                    backgroundColor: 'var(--blue-color)',
                  }}
                >
                  <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                  <div>
                    <Typography fontWeight="medium" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'var(--text-color)' }}>
                      {item.description}
                    </Typography>
                  </div>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </section>
  );
};

export default Features;
