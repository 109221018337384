import styles from './css/Intro.module.css';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const Intro = () => {
  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <section className={styles['intro-page']}>
      <h1>
        Welcome to <span>Salesdam</span>
      </h1>
      <p>
        &#8212; your data powerhouse for streamlined business success!
        Consolidate, optimize, and thrive with us as your vital pulse in the
        digital age.
      </p>
      <Box
        id="image"
        sx={() => ({
          mt: { xs: 8, sm: 10 },
          alignSelf: 'center',
          height: { xs: 200, sm: 580 },
          width: '80%',
          backgroundImage: isMobile ? 'url("/images/salesdam app responsive.png")' : 'url("/images/Salesdam app home page.png")',
          backgroundSize: 'cover',
          borderRadius: '10px',
          outline: '1px solid',
          outlineColor: alpha('#BFCCD9', 0.5),
          boxShadow: `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`,
        })}
      />
    </section>
  );
};

export default Intro;
