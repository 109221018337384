import styles from './SectionHeader.module.css';

const SectionHeader = ({ text, info }) => {
    return (
        <div className={styles['section-header']}>
            <h2>{text}</h2>
            <p>{info}</p>
        </div>
    )
};

export default SectionHeader;