import styles from './LandingPage.module.css';
import SEO from '../../common/seo/SEO';
import Navbar from '../../common/navbar/Navbar';
import Intro from './sections/Intro';
import Customers from './sections/Customers';
import Features from './sections/Features';
import FAQ from './sections/FAQ';
import Testimonials from './sections/Testimonials';
import Footer from '../../common/footer/Footer';

const LandingPage = () => {
  return (
    <div className={styles['landing-page']}>
      <SEO
        title="Home | Salesdam"
        description="At Salesdam, we specialize in consolidating your data into a digital repository, enabling you to gain a holistic perspective on your business performance from a unified platform"
      />
      <Navbar />
      <Intro />
      <Customers />
      <Features />
      <Testimonials />
      <FAQ />
      <Footer />
    </div>
  );
};

export default LandingPage;