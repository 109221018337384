import './App.css';
import PageRouter from './PageRouter';
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <div className="App">
        <PageRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;