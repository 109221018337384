import MUIButton from '@mui/material/Button';

const getStylesToApplyFromVariant = (variant, width) => {
  switch (variant) {
    case 'text':
      return {
        color: 'var(--primary-color)',
        width: width
      };
    case 'outlined':
      return {
        color: 'var(--primary-color)',
        border: '1px solid var(--primary-color)',
        width: width
      };
    case 'contained':
      return {
        background: 'var(--primary-color)',
        width: width
      };
    default:
      return {};
  }
};

const Button = ({ text, variant, size, onClick, href, width }) => {
  return (
    <MUIButton
      style={getStylesToApplyFromVariant(variant, width)}
      variant={variant}
      size={size}
      href={href}
      target="_blank"
      onClick={href ? null : onClick}
    >
      {text}
    </MUIButton>
  );
};

export default Button;