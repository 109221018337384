import Link from '@mui/material/Link';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionItem = ({
  expanded,
  onChange,
  panel,
  title,
  description,
  linkText,
}) => {
  return (
    <Accordion expanded={expanded === panel} onChange={onChange(panel)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panel}d-content`}
        id={`${panel}d-header`}
      >
        <Typography component="h3" variant="subtitle2">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" gutterBottom>
          {description}
          {linkText && (
            <>
              {' '}
              You can reach our customer support team by emailing
              <Link> {linkText} </Link> or calling our toll-free number.
              We&apos;re here to assist you promptly.
            </>
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionItem;