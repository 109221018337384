import styles from './Navbar.module.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Button from '../button/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { useMediaQuery } from '@mui/material';

const Navbar = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const toggleDrawer = (open) => () => {
    setOpenDrawer(open);
  };

  return (
    <nav className={styles['navbar']}>
      <img src="/images/Main Logo.png" alt="salesdam logo" />

      {!isMobile && (
        <ul className={styles['nav-items']}>
          <li>
            <HashLink smooth to="/#features">
              Features
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#testimonials">
              Testimonials
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#faq">
              FAQ
            </HashLink>
          </li>
          <li>
            <Button
              text="Sign in"
              variant="outlined"
              size="small"
              href={process.env.REACT_APP_SALESDAM_LOGIN_URL}
            />
          </li>
          <li>
            <Button text="Sign up" variant="contained" size="small" onClick={() => navigate('/signup')} />
          </li>
        </ul>
      )}

      {isMobile && (
        <IconButton edge="start" aria-label="menu" onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
      )}
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <ul
          className={styles['burger-nav-items']}
          style={{ width: 300, color: '#fbfcfe' }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <li>
            <HashLink smooth to="/#features">
              Features
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#testimonials">
              Testimonials
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#faq">
              FAQ
            </HashLink>
          </li>
          <li>
            <Button
              width="100%"
              text="Sign in"
              variant="outlined"
              size="small"
              href={process.env.REACT_APP_SALESDAM_LOGIN_URL}
            />
          </li>
          <li>
            <Button
              width="100%"
              text="Sign up"
              variant="contained"
              size="small"
            />
          </li>
        </ul>
      </Drawer>
    </nav>
  );
};

export default Navbar;
