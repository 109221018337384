import styles from './css/FAQ.module.css';
import { useState } from 'react';
import SectionHeader from '../../../common/header/SectionHeader';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AccordionItem from '../../../common/accordion/AccordionItem';

const faqItems = [
  {
    panel: 'panel1',
    title: 'How do I contact customer support if I have a question or issue?',
    description: '',
    linkText: 'support@salesdam.com',
  },
  {
    panel: 'panel2',
    title: 'What data security measures do you have in place?',
    description:
      'We prioritize data security with encryption, secure data centers, and regular security audits. Our platform complies with industry standards to ensure your data is protected.',
  },
  {
    panel: 'panel3',
    title: 'Can I customize the app to fit my specific business needs?',
    description:
      'Yes, our app is highly customizable. You can tailor features, dashboards, roles and permissions to meet your unique business requirements. Contact our support team for assistance with customizations.',
  },
  {
    panel: 'panel4',
    title: 'Do you offer training and support for new users?',
    description:
      'Absolutely! We provide comprehensive training programs and 24/7 support to ensure that new users can effectively utilize our platform. Visit our support page or contact us for more details.',
  }
];

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section id="faq" className={styles['FAQ']}>
      <SectionHeader
        text="Frequently Asked Questions"
        info="Get answers to the most frequently asked questions about Salesdam."
      />
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box sx={{ width: '100%' }}>
          {faqItems.map((item) => (
            <AccordionItem
              key={item.panel}
              expanded={expanded}
              onChange={handleChange}
              panel={item.panel}
              title={item.title}
              description={item.description}
              linkText={item.linkText}
            />
          ))}
        </Box>
      </Container>
    </section>
  );
};

export default FAQ;
